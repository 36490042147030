import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitDialog = _resolveComponent("WaitDialog")!
  const _component_m_btn = _resolveComponent("m-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_WaitDialog, {
        isActive: $setup.isWaiting,
        title: "What are you waiting for?"
      }, null, 8 /* PROPS */, ["isActive"]),
      _createVNode(_component_v_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    title: "Buttons",
                    class: "text-left btns"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "pa-4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_m_btn, { text: "Default" })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_col, { cols: "2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_m_btn, {
                                color: "primary",
                                text: "Primary"
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_col, { cols: "2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_m_btn, {
                                color: "secondary",
                                text: "Secondary"
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_col, { cols: "2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_m_btn, {
                                color: "accent",
                                text: "Accent"
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    title: "Dialogs",
                    class: "text-left"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "pa-4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "3" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_dialog, {
                                modelValue: $setup.dialog,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.dialog) = $event)),
                                "max-width": "400",
                                persistent: ""
                              }, {
                                activator: _withCtx(({ props: activatorProps }) => [
                                  _createVNode(_component_m_btn, _mergeProps(activatorProps, { class: "moe-button" }), {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Confirmation ")
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card, {
                                    "prepend-icon": "mdi-map-marker",
                                    text: "Do you really want to do that?",
                                    title: "Are you sure?"
                                  }, {
                                    actions: _withCtx(() => [
                                      _createVNode(_component_v_spacer),
                                      _createVNode(_component_m_btn, {
                                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.dialog = false)),
                                        text: "Nevermind",
                                        color: "primary"
                                      }),
                                      _createVNode(_component_m_btn, {
                                        onClick: $setup.confirmDialog,
                                        text: "Yes!"
                                      }, null, 8 /* PROPS */, ["onClick"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["modelValue"])
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_col, { cols: "3" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_m_btn, {
                                onClick: $setup.startWaiting,
                                text: "Wait for it..."
                              }, null, 8 /* PROPS */, ["onClick"])
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}