import { createApp } from 'vue'
import App from './App.vue'
import { router } from '@/services/router'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { Tell } from '@/services/Tell'
import { EntraAuthService } from '@/services/EntraAuthService'
import { CommercialApi } from '@/services/CommercialApi'
import { EntraAuthConfig as entraAuthConfig } from '@/services/EntraAuthConfig'
import { CommercialApiConfig as commercialApiConfig } from '@/services/CommercialApiConfig'
import telemetry from '@/services/telemetry'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import 'bootstrap/dist/css/bootstrap.css'
import './assets/styles/main.scss'
import 'vuetify/styles'
import "@mdi/font/css/materialdesignicons.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { GoogleMap } from 'vue3-google-map';
import { useAppStore } from '@/stores/app.store';
import colors from 'vuetify/util/colors'

// services 


// stores 

// styles

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

app.use(router);
app.use(pinia);

// Detect system preference for dark mode
const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
let defaultTheme = prefersDarkMode ? 'dark' : 'light';

// Check the app store for current settings
const appStore = useAppStore();

// if the app store has settings; use those
if(appStore.getTheme != null)
{
	if(appStore.getTheme != 'system')
	{
		defaultTheme = appStore.getTheme;
	}
}
else
{
	// save this to the store
	if(prefersDarkMode)
	{
		appStore.setTheme('system');
	}
	else
	{
		appStore.setTheme(defaultTheme);
	}
}

const vuetify = createVuetify({
	components,
	directives,
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi,
		},
	},
	theme: {
		defaultTheme: defaultTheme,
		themes: {
			light: {
				dark: false,
				colors: {
					primary: '#4d7e5a',
					secondary: '#d67c3b',
					accent: '#80b9cd',
					error: '#c94241',
					info: '#6099ac',
					success: '#245e36',
					warning: '#9f7596',
					buttonColor: '#245e36'
				},
			},
			dark: {
				dark: true,
				colors: {
					primary: colors.blue.lighten1,
					secondary: colors.green.lighten1,
					accent: colors.blueGrey.darken3,
					error: colors.red.lighten1,
					info: colors.blueGrey.lighten1,
					success: colors.green.lighten1,
					warning: colors.amber.lighten1,
				},
			},
		}
	}
});

app.use(vuetify);

app.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
		libraries: ['places'],
	}
});

app.component('GoogleMap', GoogleMap);

// Provide services for injection
// these services are available using the 'inject' function inside your components
app.provide('auth', new EntraAuthService(entraAuthConfig));
app.provide('telemetry', telemetry);
app.provide('tell', new Tell());

const commercialApi = new CommercialApi(commercialApiConfig)

app.provide('policyService', commercialApi);
app.provide('rateService', commercialApi);

app.mount('#app');