<template>
	<v-container>
		<WaitDialog :isActive="isWaiting" title="What are you waiting for?" />

		<v-container>
			<v-row>
				<v-col cols="12" md="12">
					<v-card title="Buttons" class="text-left btns">
						<v-row class="pa-4">
							<v-col cols="2">
								<m-btn text="Default"/>
							</v-col>
							<v-col cols="2">
								<m-btn color="primary" text="Primary" />
							</v-col>
							<v-col cols="2">
								<m-btn color="secondary" text="Secondary" />
							</v-col>
							<v-col cols="2">
								<m-btn color="accent" text="Accent" />
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="12">
					<v-card title="Dialogs" class="text-left">
						<v-row class="pa-4">
							<v-col cols="3">
								<v-dialog v-model="dialog" max-width="400" persistent>
									<template v-slot:activator="{ props: activatorProps }">
										<m-btn v-bind="activatorProps" class="moe-button">
											Confirmation
										</m-btn>
									</template>
							
									<v-card prepend-icon="mdi-map-marker"
										text="Do you really want to do that?"
										title="Are you sure?">
										<template v-slot:actions>
											<v-spacer></v-spacer>
							
											<m-btn @click="dialog = false" text="Nevermind" color="primary" />
							
											<m-btn @click="confirmDialog" text="Yes!" />
										</template>
									</v-card>
								</v-dialog>
							</v-col>
							<v-col cols="3">
								<m-btn @click="startWaiting" text="Wait for it..." />
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	

	</v-container>
</template>
 
<script lang="ts">
import { ref, onMounted } from 'vue';
import WaitDialog from '@/components/WaitDialog.vue';
import MBtn from '@/components/BaseButton.vue';

export default {
	//name doesn't match the file name, but we're doing it like this for uri simplicity on Entra config
	//and for clarity on the vue app
	name: 'StyleGuide',
	route: {
		path: '/style-guide',
		meta: {
			requiresAuth: true,
			title: 'Style Guide'
		}
	},
	components: {
		WaitDialog,
		MBtn
	},
	props: {
		//these properties are exposed to consumers of this component
	},
	setup() {
		const dialog = ref<boolean>(false);
		const isWaiting = ref<boolean>(false);

		const confirmDialog = () => {
			dialog.value = false;
		}

		const startWaiting = () => {
			isWaiting.value = true;
			setTimeout(() => {
				isWaiting.value = false;
			}, 3000);
		}

		onMounted(async () => {
			//do something when the component is mounted
		});

		return {
			dialog,
			isWaiting,
			confirmDialog,
			startWaiting
		}
	}
}
</script>
<style scoped>
button {
	margin-right: var(--v-spacing-default);
}
</style>