<template>
	<v-tooltip :text="tooltip" :disabled="!tooltip">
		<template #activator="{ props }">
			<v-btn rounded="sm" 
				variant="elevated" 
				v-bind="{ ...$attrs, ...props }" 
				:class="['m-btn', $attrs.class]"
				:click="onClick">
				<template v-for="(_, slotName) in $slots" #[slotName]>
					<slot :name="slotName" />
				</template>
			</v-btn>
		</template>
	</v-tooltip>
</template>

<script>
export default {
	name: "m-btn",
	inheritAttrs: false,
	props: {
		tooltip: {
			type: String,
			default: "",
		},
	},
	methods: {
		onClick() {
			this.$emit('click');
		},
	},
	mounted() {
		// Component mounted
	},
};

</script>

<style scoped>
.m-btn {
	
}
</style>