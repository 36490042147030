import { PointResult, ResultsInput, Insured, LatLng } from '../models/CommercialDataModel';
import { getPropertyInfo, GiaResult } from './PropertyService';
import { Tell } from './Tell';
import { BillingCenterResult, BillingCenterService } from './BillingCenterService';
import { DocRepoService } from './DocumentRepoService';
import { ErrorService } from './ErrorService';
import { ref } from 'vue';
import { error } from 'console';

const tell = new Tell();
let query = '';
let autoSearch: any;
let locations: ResultsInput[] = [];
let policyQuery: string;
let loading = false;
let printMode = false;
const plrReportUrlDefault = process.env.VUE_APP_MOE_PLR_REPORT_URL_DEFAULT;
let plrReportUrlPolicy = plrReportUrlDefault;
export const electronicWorksheetURL = ref<string>('');
let policyEvaluation: string | undefined;
let goodPolicyEvaluation = false;
let waitingForPolicyEvaluation = false;
let result: any;

export const CommercialDataService = {

	policyNumberFromQueryString(): string | undefined {
		let policyNumber: string | undefined = undefined;
		const searchParams = new URLSearchParams(window.location.search.toLowerCase());

		if (searchParams.has('policynumber') === true) {
			policyNumber = searchParams.get('policynumber') ?? '';
		}

		return policyNumber;
	},

	async initMap(address: any): Promise<void> {

		const options = {
			componentRestrictions: { country: "us" },
			fields: ["address_components", "geometry", "icon", "name"],
			strictBounds: false
		};

		const addressAuto = document.getElementById('addressInput') as HTMLInputElement;
		const policyAuto = document.getElementById('policyInput') as HTMLInputElement;

		const autocompleteAddress = new google.maps.places.Autocomplete(address, options);
		const autocompletePolicy = new google.maps.places.Autocomplete(policyAuto, options);

		autoSearch = autocompleteAddress;
	},
	async addLocation(userInput: any): Promise<any> {
		locations = [];

		await this.initMap(userInput);

		const geocoder = new google.maps.Geocoder();
		const address = userInput;

		await geocoder.geocode({
			'address': address
		}, function (results, status) {

			autoSearch = results[0];
		});

		const place = autoSearch;
		const resultsInput = new ResultsInput();
		// user selected a place from the dropdown
		if (place) {
			try {
				const res: GiaResult = await getPropertyInfo(
					place.geometry.location.lat().toString(),
					place.geometry.location.lng().toString()
				);

				resultsInput.fireScore = res.giaScore;
				resultsInput.hailScore = res.hailScore;
				resultsInput.waitingForScores = false;

				resultsInput.location = place.geometry.location;
				resultsInput.address = this.getFriendlyAddress(place);

				locations.push(resultsInput);

				query = '';
			} catch (error) {
				console.error('Error fetching property info:', error);
			}
			this.geocodeLocation(resultsInput, userInput);
			console.log("locations: ", locations);
			return locations;
		} else { // user typed in the full address
			query = '';
			return this.geocodeLocation(resultsInput, userInput);
		}
	},

	async getPolicy(policy: string): Promise<PointResult> {
		const url = `${process.env.VUE_APP_MOE_COMMERCIAL_API_URL}/workbench/policy/${policy}`;
		const response = await fetch(url.toString(), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'x-api-key': process.env.VUE_APP_MOE_COMMERCIAL_API_KEY,
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			tell.error(errorData.message);
			throw new Error(errorData.message);
		}
		return await response.json();
	},

	getFriendlyAddress(place: any): string {
		const street = this.filterPlace('street_number', place.address_components);
		const route = this.filterPlace('route', place.address_components);
		const city = this.filterPlace('locality', place.address_components);
		const state = this.filterPlace('administrative_area_level_1', place.address_components);
		const zip = this.filterPlace('postal_code', place.address_components);
		let zipsuffix = this.filterPlace('postal_code_suffix', place.address_components);
		if (zipsuffix) {
			zipsuffix = `-${zipsuffix}`;
		}
		const county = this.filterPlace('administrative_area_level_2', place.address_components);

		return `${street} ${route}, ${city}, ${state} ${zip}${zipsuffix} ${county}`.trim();
	},

	filterPlace(fieldType: string, fields: any[]): string {
		const result = fields?.find(a => a.types?.includes(fieldType));
		return result?.short_name ?? '';
	},

	async geocodeLocation(resultsInput: ResultsInput, address: string): Promise<any> {
		const geocoder = new google.maps.Geocoder();
		try {
			const results = await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
				geocoder.geocode({ 'address': address }, (results, status) => {
					if (status === google.maps.GeocoderStatus.OK && results != null) {
						resolve(results);
					} else {
						reject(status);
					}
				});
			});

			resultsInput.partialMatch = results[0].partial_match;

			const res: GiaResult = await getPropertyInfo(
				results[0].geometry.location.lat().toString(),
				results[0].geometry.location.lng().toString()
			);

			resultsInput.fireScore = res.giaScore;
			resultsInput.hailScore = res.hailScore;
			resultsInput.waitingForScores = false;

			resultsInput.location = results[0].geometry.location;
			resultsInput.address = this.getFriendlyAddress(results[0]);

			locations.push(resultsInput);
			locations.sort((a, b) => a.locNum - b.locNum);

		} catch (status) {
			if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
				this.errorMessage('error', "No results found.");
			} else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
				this.errorMessage('warning', "Geocoding query limit exceeded; try again shortly.");
			} else if (status === google.maps.GeocoderStatus.ERROR || status === google.maps.GeocoderStatus.UNKNOWN_ERROR) {
				this.errorMessage('warning', "Geocoding error; unknown error occurred.");
			} else if (status === google.maps.GeocoderStatus.INVALID_REQUEST) {
				this.errorMessage('warning', "Invalid request.");
			} else if (status === google.maps.GeocoderStatus.REQUEST_DENIED) {
				this.errorMessage('warning', "Request denied; incorrect google maps api key.");
			}
			resultsInput.waitingForScores = false;
		}
	},

	errorMessage(type: string, message: string) {
		if (type === 'error') {
			tell.error(`${message}`);
		} else {
			tell.error(`${message}`);
		}
	},

	renderProfitLossReportURL(policyNumber: string | null): string {
		let plrReportUrl = plrReportUrlDefault;

		if (policyNumber != null && policyNumber.length > 0) {
			plrReportUrl = `${plrReportUrl}?PolicyNumber1=${encodeURIComponent(policyNumber)}`;
		}

		return plrReportUrl;
	},

	async importPolicy(policyNumber: string): Promise<any> {


		locations = [];
		policyQuery = policyNumber;

		if (!policyQuery) {
			return;
		}

		policyQuery = policyQuery.toUpperCase();

		if (policyQuery.length < 12) {
			return;
		}

		await this.initMap(policyNumber);

		loading = true;
		locations = [];
		plrReportUrlPolicy = plrReportUrlDefault;

		waitingForPolicyEvaluation = true;
		policyEvaluation = undefined;

		// Uppercase what was typed...
		policyQuery = policyQuery.toUpperCase();

		try {
			const data: PointResult = await this.getPolicy(policyQuery.replace(/\s/g, ''));
			let policyNumberForReport = "";

			if (data?.insureds && data.insureds.length > 0) {
				policyNumberForReport = `${data.insureds[0].symbol}${data.insureds[0].policyNo}`;
				plrReportUrlPolicy = this.renderProfitLossReportURL(policyNumberForReport);
			}

			if (data.addresses.length == 0) {
				loading = false;
				tell.error("Policy not found.");
				return;
			} else {
				tell.success("Policy found.");
			}

			for (const a of data.addresses) {
				// FACT-2277 Do not include Street2 in the address values sent to be geo-coded.
				const address = `${a.address1} ${a.city} ${a.state} ${a.zip}`;
				const resultsInput = new ResultsInput();

				resultsInput.locNum = a.locNum;
				resultsInput.address = address;

				if (data.locationsTIV) {
					resultsInput.insLineTIVs = data.locationsTIV.filter(tiv => tiv.location == a.locNum).map(ativ => ({
						insLine: ativ.insuranceLine,
						tiv: ativ.tiv
					}));
				}

				await this.geocodeLocation(resultsInput, address);
			}

			//   policyNumberForReport = policyQuery;
			const res: BillingCenterResult = await BillingCenterService.getPolicyEvaluation(policyNumberForReport);
			waitingForPolicyEvaluation = false;
			policyEvaluation = res?.policyEvaluation;
			goodPolicyEvaluation = res?.policyEvaluation == "Acceptable";

			if (policyNumberForReport && policyNumberForReport.trim() != '') {
				// FACT-2196 call the onbase api to see if there's an 'Electronic Worksheet' document for this policy number.
				// IF a document is found then it's ID will be used to construct a URL suitable to render the document in a browser.
				electronicWorksheetURL.value = "";
				const docResponse: any = await DocRepoService.getDocument("Commercial and Farm Output", "Electronic Copy", [["Policy #", policyNumberForReport]], true);
				if (docResponse
					&& docResponse.Documents
					&& docResponse.Documents.length > 0
					&& docResponse.Documents[0]
					&& docResponse.Documents[0].ID
					&& docResponse.Documents[0].ID != null
					&& docResponse.Documents[0].ID != 0) {
					// Document ID returned from OnBase API.
					electronicWorksheetURL.value = `${process.env.VUE_APP_ONBASE_API_URL}/OnBaseDocumentServiceREST/api/v1/documents/${encodeURIComponent(docResponse.Documents[0].ID)}/content`;
				}
			}
			console.log("electronicWorksheetURL: ", electronicWorksheetURL);
		} catch (error) {
			console.error('Error importing policy:', error);
		} finally {
			loading = false;
		}

		return locations;
	},

	togglePrintMode(): void {
		printMode = !printMode;
	},

	stringToBase64(str: string) {
		// Convert string to byte array
		const bytes = new TextEncoder().encode(str);

		// Convert byte array to Base64 string in a way that avoids call stack size issues
		const binaryString = Array.from(bytes).map(byte => String.fromCharCode(byte)).join('');
		return btoa(binaryString);
	},

	//removes extra elements from the html that we don't want
	getTruncatedHtml(): string {
		const clonedDocument = document.documentElement.cloneNode(true) as HTMLElement;

		//now rehydrate the cloned html with the current inputs' values
		const textareas = clonedDocument.querySelectorAll('textarea');

		//set the text value directly to the HTML
		textareas.forEach((textarea, index) => {
			textarea.textContent = (document.querySelectorAll('textarea')[index] as HTMLTextAreaElement).value;
		});

		//we need to remove quite a few things so we don't send a bunch of js over to onbase
		const elementsToRemove = clonedDocument.querySelectorAll('.navbar, .notyf, script');

		elementsToRemove.forEach(element => element.remove());

		return clonedDocument.outerHTML;
	},

	async exportToDocument(): Promise<void> {

		if (loading || !policyQuery || policyQuery.length < 12) {
			tell.error("Good try! We need a policy to send something to OnBase. Find one.");
			return;
		}
		// 2023.10.10 - BSU - this attribute

		// put the html document into "print" mode
		printMode = true;

		tell.success("Preparing to send to OnBase...");

		// Wait for 3 seconds
		await new Promise(resolve => setTimeout(resolve, 3000));

		// TODO: fetch all images and update their content
		// this.convertHtmlImages

		// iterate the img tags inside of the html copy and base64 encode them
		const entireHtml = this.getTruncatedHtml();

		// encode everything to base64
		const bitstream = this.stringToBase64(entireHtml);
		// let bitstream = this.htmlStringToBitstream(entireHtml);

		// figure out what type of doc to send
		const docType = policyQuery.charAt(0) === 'F' ? "FRM - UW Surveys" : "COM - UW Surveys";

		// now, do we have a policy number or a quote?
		const quoteOrPolicy = policyQuery.charAt(2) === 'Q' ? "Quote #" : "Policy #";

		const response = await DocRepoService.postDocument(docType, bitstream, "html", {
			// OnBase requires policy # to be sent w/out the module
			[quoteOrPolicy]: policyQuery.slice(0, -2),
			"COM/FRM Item Desc": "Workbench Results",
			"Last Name / Business Name": "Workbench Results"
		});

		if (response.DocumentDate === undefined) {
			console.log("exportToOnBase: error");
			console.log('OnBase', response);
			ErrorService(response, 'postDocument', `Error posting to OnBase`);
		} else {

			console.log("exportToOnBase: success");
			tell.success("Awesome. We sent this to OnBase for you.");
			printMode = false;
			console.log("exportToOnBase: complete");
		}
	}
}